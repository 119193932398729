/**
 * = Navs
 */

.nav-link {
    color: $nav-link-color;

    &:hover,
    &.active {
        color: $nav-link-hover-color;
        img{
            opacity: inherit;
            transition: $transition-base;
        }
    }

    span{
        position: relative;
    }
}

//Tabs 

.nav-tabs {
    .nav-item {
        margin-bottom:0;
    }
  
    .nav-link {
        border: 0;
        padding:1rem 1rem;
        
        &.active{
            color: $white;
            background-color: $primary;
        }
    }

    &.nav-dark{
        .nav-link{
            &.active{
                color: $white;
                background-color: #12358a;
            }
        }
    }

    &.nav-light{
        .nav-link{
            &.active{
                color: $black;
                background-color: $light;
            }
            &:hover{
                color:$black;
            }
        }
    }
}

// Pills 

.nav-pills {
    .nav-item:not(:last-child) {
        padding-right: $nav-pills-space-x;
    }

    .nav-link { 
    	padding: $nav-pills-padding-y $nav-pills-padding-x;
        transition: $transition-base;
        box-shadow:$nav-link-shadow;
        border:$nav-pills-border-width solid $nav-pills-border-color;

        &.avatar-link{
            border:0;
        }

        &:hover {
            color: $nav-pills-link-hover-color;
        }
    }
 
    .nav-link.active,
    .show > .nav-link {
        color: $nav-pills-link-active-color;
        background-color: $nav-pills-link-active-bg;
        border-color:$nav-pills-link-active-color;
    }

    &.rounded{
        .nav-link{
            border-radius:30px;
        }
    }

    &.bordered-pill-md{
        .nav-link{
            border: $border-width-md solid $gray-200;
            font-weight:$font-weight-bold;
        }
    }

    &.vertical-tab { 
        .nav-link{
            margin-bottom: .625rem;
        }
        .nav-item:not(:last-child) {
            padding-right:0;
        }
    }

    @include media-breakpoint-down(xs) {
        .nav-item {
            margin-bottom: $spacer;
        }
    }
}

@include media-breakpoint-down(sm) {
    .nav-pills:not(.nav-pills-circle) {
        .nav-item {
            padding-right: 0;
        }
    }
} 


// Navs circle

.nav-pill-circle {
    .nav-link {
        @include display-flex();
        text-align: center;
        height: 80px;
        width: 80px;
        padding: 0;
        box-shadow: $nav-link-shadow; 
        border-radius: $nav-pills-border-circle;
        align-items: center;
        justify-content: center;

        &.avatar-link{
            box-shadow: none;
        }
    }

    &.vertical-tab { 
        .nav-link-icon {
            i, svg {
                font-size: 1.5rem;
            }
        }
        .nav-item:not(:last-child) {
            padding-right:0;
        }
    }

    .nav-link-icon {
        i, svg {
            font-size: 1.25rem;
            margin:0;
            display: block;
        }
    }
}

// Navs square

.nav-pill-square {
    .nav-link {
        text-align: center;
        min-width: 80px;
        box-shadow: $nav-link-shadow;
        @include display-flex();
        align-items: center;
        justify-content: center;
    }

    &.vertical-tab{
        .nav-link{
            margin-bottom: .625rem;
            min-width: 100px;
        }
        .nav-item:not(:last-child) {
            padding-right:0;
        }
    }

    .nav-link-icon {
        i, svg {
            font-size: 1.25rem;
            margin:0;
            display: block;
            line-height: 50px;
        }
    }
}

// Nav wrapper 
.nav-wrapper {
    padding: 1rem 0;
    @include border-top-radius($card-border-radius);

    + .card {
        @include border-top-radius(0);
        @include border-bottom-radius($card-border-radius);
    }
}

// Tabbable tabs
//
// Hide tabbable panes to start, show them when `.active`

.tab-content {
    > .tab-pane {
        display: none;
        pre{
            padding:0;
            margin:0;
        }
    }
    
    > .active {
        display: block;
    }
}
.top-banner,.topbar{
    /*box-shadow: -1px -1px 10px #9b9b9b;*/
    box-shadow: -6px -1px 7px #9b9b9b;
    background-color: #fff;
    .guard{
        padding-top: 18px;
        text-align: center;
        padding-bottom: 10px;
        .tm{
            font-size: .3em;top: -2em;
        }
    }
    .logo{
        text-align: right;
    }
    
    
}
.topbar{
    padding: 5px 0;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    box-shadow: 0 0 10px rgb(142 142 142 / 20%);
    width: 100vw;
}
.site-menu{
    margin-top: -1rem;
    .navbar-nav{
margin-left: 0px;

    }
    .nav-item{
        background-color: #0051a5;
        border-radius: 0px 0px 12px 12px;
        padding:0 10px;
        
        
    list-style-type:none;
    }
    .nav-item .nav-link{
     color:white;
     font-weight:bold;
    }
    .navactive,.nav-item:hover{
        background-color: #bfc6d1;
        
    }
    .navactive .nav-link,.nav-item:hover .nav-link{
     color:black;
    }
}
.requestprogress  {
    .loader-element{
         transition: spin 0.3s linear infinite;
        animation: spin 3s linear infinite;
        margin: 0 auto;
        display: block;
    }
   
}